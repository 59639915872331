<template>
  <fki-container
    fixed
    top="0"
    bottom="0"
    left="0"
    width="100%"
  />
</template>

<script>

export default {
  name: 'tv'
}
</script>
